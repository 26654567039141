.explore {
    margin-top: 100px;
}





 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
    .explore--t1 {
        text-align: center;
        font-weight: 700;
        margin-top: 82px;
    }
    
    .explore--boxes {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 36px;
        margin: 32px 15px;
    }

    .explore--box {
        width: 100%;
        height: fit-content;
        padding: 18px 24px;
        padding-top: 24px;
        background-color: #fff;
        border-radius: 25px;
    }
    
    .explore--box:hover {
        background-color: rgb(0, 0, 0, 0.2);
        cursor: pointer;
    }
 }

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) {
    .explore--t1 {
        text-align: center;
        font-weight: 700;
        margin-top: 152px;
    }
    
    .explore--boxes {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 36px;
        margin: 32px 150px;
    }

    .explore--box {
        width: 100%;
        height: fit-content;
        padding: 18px 24px;
        padding-top: 24px;
        background-color: #fff;
        border-radius: 25px;
    }
    
    .explore--box:hover {
        background-color: rgb(0, 0, 0, 0.2);
        cursor: pointer;
    }
 }
 
 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {
    .explore--t1 {
        text-align: center;
        font-weight: 700;
        margin-top: 152px;
    }
    
    .explore--boxes {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        gap: 36px;
        margin: 32px 30px;
    }

    .explore--box {
        width: 350px;
        height: fit-content;
        padding: 18px 24px;
        padding-top: 24px;
        background-color: #fff;
        border-radius: 25px;
    }
    
    .explore--box:hover {
        background-color: rgb(0, 0, 0, 0.2);
        cursor: pointer;
    }
 }
 
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {
    .explore--t1 {
        text-align: center;
        font-weight: 700;
        margin-top: 142px;
    }
    
    .explore--boxes {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 36px;
        margin: 32px 100px;
        justify-content: center;
    }

    .explore--box {
        width: 350px;
        height: fit-content;
        padding: 18px 24px;
        padding-top: 24px;
        background-color: #fff;
        border-radius: 25px;
    }
    
    .explore--box:hover {
        background-color: rgb(0, 0, 0, 0.2);
        cursor: pointer;
    }
 }
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1200px) {
    .explore--t1 {
        text-align: center;
        font-weight: 700;
        margin-top: 32px;
    }
    
    .explore--boxes {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 36px;
        margin: 32px 100px;
        justify-content: center;
    }

    .explore--box {
        width: 350px;
        height: fit-content;
        padding: 18px 24px;
        padding-top: 24px;
        background-color: #fff;
        border-radius: 25px;
    }

    .explore--box:hover {
        background-color: rgb(0, 0, 0, 0.2);
        cursor: pointer;
    }
 } 



.explore--box-lg {
    width: 15px;
    margin-top: -6px;
}

.explore--circle {
    text-align: right;
    display: flex;
    justify-content: end;
    margin-top: -20px;
}

.explore--t2 {
    padding-left: 18px;
}

.explore--t3a {
    font-size: 20px;
}

.explore--t3b {
    text-align: right;
    margin-top: -30px;
    display: flex;
    justify-content: end;
}

.explore--t4a {
    font-size: 16px;
}

.explore--t4b {
    text-align: right;
    margin-top: -22px;
    display: flex;
    justify-content: end;
}

.explore--t5 {
    text-align: right;
}