.the-third-modal {
    width: 480px;
    border-radius: 5px;
    margin-top: 100px;
} 

.the-third-error {
    font-size: 152px;
    color: #f27474;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.the-third-error-text {
    color: rgba(0, 0, 0, 0.65);
    font-size: 27px;
    font-weight: 600;
    text-align: center;
}

.the-third-error-text2 {
    color: rgba(0, 0, 0, 0.64);
    font-size: 16px;
    text-align: center;
    margin-bottom: 80px;
}

.the-third-modal-ok {
    width: 70px;
    height: 40px;
    background-color: #78cbf2;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    position: absolute;
    right:  24px;
    bottom: 24px;
}