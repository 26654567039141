body {
    background-color: #ededed;
}

.link {
    text-decoration: none;
}

.header--btnV2 {
    padding: 8.5px 14px;
    border: 1px solid rgb(46, 125, 50);
    border-radius: 100px;
    background: none;
    color: rgb(46, 125, 50);
    cursor: pointer;
}

 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
    .header {
        padding: 6px 36px;
    }
    
    .header--list {
        display: none;
        flex-direction: column;
        /* gap: 0px; */
        z-index: 1;
        position: absolute;
        background-color: #fff;
        padding: 24px;
        margin-top: 50px;
        border: 1px solid #00000010;
    }

    .header--nav {
        display: block;
        font-size: 24px;
        position: relative;
        top: 46px;
        z-index: 2;
    }

    .header--nav2 {
        display: none;
        font-size: 24px;
        position: relative;
        top: 56px;
        left: 6px;
        z-index: 2;
    }
    
    .header--l {
        color: rgba(0, 0, 0, 0.6);
        padding: 6px 8px;
        font-size: 16px;
        font-weight: 700;
    }
    
    .active {
        background-color: rgba(25, 118, 210, 0.04);
    }
    
    .header--l:hover {
        background-color: rgba(25, 118, 210, 0.04);
        cursor: pointer;
    }
    
    .header--right {
        margin-top: 14px;
    }
    
    .header--icon {
        font-size: 20px;
        display: none;
    }
    
    .header--t1 {
        font-size: 20px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.9);
        margin-left: 16px;
        display: none;
    }
    
    .header--btn1 {
        color: rgba(0, 0, 0, 0.87);
        padding: 10.5px 14px;
        border: 1px solid rgb(0, 0, 0);
        border-radius: 100px;
        background: none;
        margin-left: 16px;
        display: none;
        z-index: 5;
    }
    
    .header--btn2 {
        padding: 8.5px 14px;
        border: 1px solid rgb(46, 125, 50);
        border-radius: 100px;
        background: none;
        color: rgb(46, 125, 50);
        margin-left: 130px;
    }
    
    
    
    
    .home {
        margin-top: 35%;
    }
    
    .home--t1 {
        font-size: 20px;
    }
    
    
    .home--btn {
        padding: 8.5px 14px;
        border: 1px solid rgb(46, 125, 50);
        border-radius: 100px;
        background: none;
        color: rgb(46, 125, 50);
        margin-left: 8px;
    }
    
    
    
    
    .footer {
        position: absolute;
        bottom: 0;
        margin: 12px 10px;
    }
    
    .footer2 {
        margin: 12px 10px;
    }
    
    .footer--icons {
        margin-top: 22px;
        margin-right: 24px;
    }
    
    .footer--icon {
        border: 2px solid #00000054;
        color: rgba(0, 0, 0, 0.54);
        font-size: 20px;
        padding: 4px;
        border-radius: 100%;
        margin-right: 10px;
    }
    
    .footer--icon2 {
        color: rgba(0, 0, 0, 0.54);
        font-size: 24px;
        padding: 4px;
    }
    
    .footer--t1 {
        margin-bottom: -18px;
        opacity: 0.6;
        cursor: pointer;
    }
    
    .footer--t1:hover {
        opacity: 1;
    }
    
    .footer--hover {
        opacity: 0.6;
        padding-left: 6px;
        padding-right: 6px;
        cursor: pointer;
    }
    
    .footer--hover:hover {
        opacity: 1;
    }
 }

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) {
    .header {
        padding: 6px 36px;
    }
    
    .header--list {
        display: flex;
        /* gap: 0px; */
    }
    
    .header--l {
        color: rgba(0, 0, 0, 0.6);
        padding: 6px 8px;
        font-size: 16px;
        font-weight: 700;
    }
    
    .active {
        background-color: rgba(25, 118, 210, 0.04);
    }
    
    .header--l:hover {
        background-color: rgba(25, 118, 210, 0.04);
        cursor: pointer;
    }
    
    .header--right {
        margin-top: 14px;
    }
    
    .header--icon {
        font-size: 20px;
    }
    
    .header--t1 {
        font-size: 20px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.9);
        margin-left: 16px;
    }
    
    .header--btn1 {
        color: rgba(0, 0, 0, 0.87);
        padding: 10.5px 14px;
        border: 1px solid rgb(0, 0, 0);
        border-radius: 100px;
        background: none;
        margin-left: 16px;
    }
    
    .header--btn2 {
        padding: 8.5px 14px;
        border: 1px solid rgb(46, 125, 50);
        border-radius: 100px;
        background: none;
        color: rgb(46, 125, 50);
        margin-left: 8px;
    }
    
    
    
    
    
    .home {
        margin-top: 30%;
    }
    
    .home--t1 {
        font-size: 20px;
    }
    
    
    .home--btn {
        padding: 8.5px 14px;
        border: 1px solid rgb(46, 125, 50);
        border-radius: 100px;
        background: none;
        color: rgb(46, 125, 50);
        margin-left: 8px;
    }
    
    
    
    
    .footer {
        position: absolute;
        bottom: 0;
        margin: 12px 10px;
    }
    
    .footer2 {
        margin: 12px 10px;
    }
    
    .footer--icons {
        margin-top: 22px;
        margin-right: 24px;
    }
    
    .footer--icon {
        border: 2px solid #00000054;
        color: rgba(0, 0, 0, 0.54);
        font-size: 20px;
        padding: 4px;
        border-radius: 100%;
        margin-right: 10px;
    }
    
    .footer--icon2 {
        color: rgba(0, 0, 0, 0.54);
        font-size: 24px;
        padding: 4px;
    }
    
    .footer--t1 {
        margin-bottom: -18px;
        opacity: 0.6;
        cursor: pointer;
    }
    
    .footer--t1:hover {
        opacity: 1;
    }
    
    .footer--hover {
        opacity: 0.6;
        padding-left: 6px;
        padding-right: 6px;
        cursor: pointer;
    }
    
    .footer--hover:hover {
        opacity: 1;
    }
 }
 
 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {
    .header {
        padding: 6px 36px;
    }
    
    .header--list {
        display: flex;
        /* gap: 0px; */
    }
    
    .header--l {
        color: rgba(0, 0, 0, 0.6);
        padding: 6px 8px;
        font-size: 16px;
        font-weight: 700;
    }
    
    .active {
        background-color: rgba(25, 118, 210, 0.04);
    }
    
    .header--l:hover {
        background-color: rgba(25, 118, 210, 0.04);
        cursor: pointer;
    }
    
    .header--right {
        margin-top: 14px;
    }
    
    .header--icon {
        font-size: 20px;
    }
    
    .header--t1 {
        font-size: 20px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.9);
        margin-left: 16px;
    }
    
    .header--btn1 {
        color: rgba(0, 0, 0, 0.87);
        padding: 10.5px 14px;
        border: 1px solid rgb(0, 0, 0);
        border-radius: 100px;
        background: none;
        margin-left: 16px;
    }
    
    .header--btn2 {
        padding: 8.5px 14px;
        border: 1px solid rgb(46, 125, 50);
        border-radius: 100px;
        background: none;
        color: rgb(46, 125, 50);
        margin-left: 8px;
    }
    
    
    
    
    
    .home {
        margin-top: 25%;
    }
    
    .home--t1 {
        font-size: 20px;
    }
    
    
    .home--btn {
        padding: 8.5px 14px;
        border: 1px solid rgb(46, 125, 50);
        border-radius: 100px;
        background: none;
        color: rgb(46, 125, 50);
        margin-left: 8px;
    }
    
    
    
    
    .footer {
        position: absolute;
        bottom: 0;
        margin: 12px 10px;
    }
    
    .footer2 {
        margin: 12px 20px;
    }
    
    .footer--icons {
        margin-top: 22px;
        margin-right: 24px;
    }
    
    .footer--icon {
        border: 2px solid #00000054;
        color: rgba(0, 0, 0, 0.54);
        font-size: 20px;
        padding: 4px;
        border-radius: 100%;
        margin-right: 10px;
    }
    
    .footer--icon2 {
        color: rgba(0, 0, 0, 0.54);
        font-size: 24px;
        padding: 4px;
    }
    
    .footer--t1 {
        margin-bottom: -18px;
        opacity: 0.6;
        cursor: pointer;
    }
    
    .footer--t1:hover {
        opacity: 1;
    }
    
    .footer--hover {
        opacity: 0.6;
        padding-left: 6px;
        padding-right: 6px;
        cursor: pointer;
    }
    
    .footer--hover:hover {
        opacity: 1;
    }
 }
 
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {
    .header {
        padding: 6px 36px;
    }
    
    .header--list {
        display: flex;
        /* gap: 0px; */
    }
    
    .header--l {
        color: rgba(0, 0, 0, 0.6);
        padding: 6px 8px;
        font-size: 16px;
        font-weight: 700;
    }
    
    .active {
        background-color: rgba(25, 118, 210, 0.04);
    }
    
    .header--l:hover {
        background-color: rgba(25, 118, 210, 0.04);
        cursor: pointer;
    }
    
    .header--right {
        margin-top: 14px;
    }
    
    .header--icon {
        font-size: 20px;
    }
    
    .header--t1 {
        font-size: 20px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.9);
        margin-left: 16px;
    }
    
    .header--btn1 {
        color: rgba(0, 0, 0, 0.87);
        padding: 10.5px 14px;
        border: 1px solid rgb(0, 0, 0);
        border-radius: 100px;
        background: none;
        margin-left: 16px;
    }
    
    .header--btn2 {
        padding: 8.5px 14px;
        border: 1px solid rgb(46, 125, 50);
        border-radius: 100px;
        background: none;
        color: rgb(46, 125, 50);
        margin-left: 8px;
    }
    
    
    
    
    
    .home {
        margin-top: 15%;
    }
    
    .home--t1 {
        font-size: 20px;
    }
    
    
    .home--btn {
        padding: 8.5px 14px;
        border: 1px solid rgb(46, 125, 50);
        border-radius: 100px;
        background: none;
        color: rgb(46, 125, 50);
        margin-left: 8px;
    }
    
    
    
    
    .footer {
        position: absolute;
        bottom: 0;
        margin: 12px 30px;
    }
    
    .footer2 {
        margin: 12px 30px;
    }
    
    .footer--icons {
        margin-top: 22px;
        margin-right: 24px;
    }
    
    .footer--icon {
        border: 2px solid #00000054;
        color: rgba(0, 0, 0, 0.54);
        font-size: 20px;
        padding: 4px;
        border-radius: 100%;
        margin-right: 10px;
    }
    
    .footer--icon2 {
        color: rgba(0, 0, 0, 0.54);
        font-size: 24px;
        padding: 4px;
    }
    
    .footer--t1 {
        margin-bottom: -18px;
        opacity: 0.6;
        cursor: pointer;
    }
    
    .footer--t1:hover {
        opacity: 1;
    }
    
    .footer--hover {
        opacity: 0.6;
        padding-left: 6px;
        padding-right: 6px;
        cursor: pointer;
    }
    
    .footer--hover:hover {
        opacity: 1;
    }
 }
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1200px) {
    .header {
        padding: 6px 36px;
    }
    
    .header--list {
        display: flex;
        /* gap: 0px; */
    }
    
    .header--l {
        color: rgba(0, 0, 0, 0.6);
        padding: 6px 8px;
        font-size: 16px;
        font-weight: 700;
    }
    
    .active {
        background-color: rgba(25, 118, 210, 0.04);
    }
    
    .header--l:hover {
        background-color: rgba(25, 118, 210, 0.04);
        cursor: pointer;
    }
    
    .header--right {
        margin-top: 14px;
    }
    
    .header--icon {
        font-size: 20px;
    }
    
    .header--t1 {
        font-size: 20px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.9);
        margin-left: 16px;
    }
    
    .header--btn1 {
        color: rgba(0, 0, 0, 0.87);
        padding: 10.5px 14px;
        border: 1px solid rgb(0, 0, 0);
        border-radius: 100px;
        background: none;
        margin-left: 16px;
    }
    
    .header--btn2 {
        padding: 8.5px 14px;
        border: 1px solid rgb(46, 125, 50);
        border-radius: 100px;
        background: none;
        color: rgb(46, 125, 50);
        margin-left: 8px;
    }
    
    
    
    
    
    .home {
        margin-top: 15%;
    }
    
    .home--t1 {
        font-size: 20px;
    }
    
    
    .home--btn {
        padding: 8.5px 14px;
        border: 1px solid rgb(46, 125, 50);
        border-radius: 100px;
        background: none;
        color: rgb(46, 125, 50);
        margin-left: 8px;
    }
    
    
    
    
    .footer {
        position: absolute;
        bottom: 0;
        margin: 12px 120px;
    }
    
    .footer2 {
        margin: 12px 120px;
    }
    
    .footer--icons {
        margin-top: 22px;
        margin-right: 24px;
    }
    
    .footer--icon {
        border: 2px solid #00000054;
        color: rgba(0, 0, 0, 0.54);
        font-size: 20px;
        padding: 4px;
        border-radius: 100%;
        margin-right: 10px;
    }
    
    .footer--icon2 {
        color: rgba(0, 0, 0, 0.54);
        font-size: 24px;
        padding: 4px;
    }
    
    .footer--t1 {
        margin-bottom: -18px;
        opacity: 0.6;
        cursor: pointer;
    }
    
    .footer--t1:hover {
        opacity: 1;
    }
    
    .footer--hover {
        opacity: 0.6;
        padding-left: 6px;
        padding-right: 6px;
        cursor: pointer;
    }
    
    .footer--hover:hover {
        opacity: 1;
    }
 }